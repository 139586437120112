$businessphoneprices-go-go-green: #008a7b;
$businessphoneprices-green: #5bc1a4;

$payrollsystems-footer-text-color: #000;
$payrollsystems-footer-separator-color: #006336;
$payrollsystems-offer-call-number-color: #006336;
$payrollsystems-why-us-brief-color: #747474;
$payrollsystems-default-button-color: #006336;
$payrollsystems-default-button-hover-color: #066d3e;
$payrollsystems-default-radio-selected-bgcolor: #e4f2ec;
$payrollsystems-default-radio-text-color: #000;
$payrollsystems-default-progress-color: #9bc7af;
$payrollsystems-default-progress-bgcolor: #e8f3f8;
$payrollsystems-default-progress-buble-text-color: #013d21;

:export {
    green: $businessphoneprices-green;
    whyUsBriefColor: $payrollsystems-why-us-brief-color;
    offerCallNumber: $payrollsystems-offer-call-number-color;
    defaultButtonColor: $payrollsystems-default-button-color;
    defaultProgressColor: $payrollsystems-default-progress-color;
}
